import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';

export default () => (
  <>
    <Helmet title="About" />

    <Intro
      title="Malbygg is a craft brewery founded in Reykjavik, Iceland"
      text={
        <React.Fragment>
          <p>Malbygg, located in Reykjavík, was founded in 2017 and we started brewing in February 2018. Our focus has been on brewing beers that we want to drink. We are always open to experimenting and constantly try to improve our beers. We package all of our beers in cans and/or kegs except barrel aged beers, they go into bottles. We mostly brew hoppy beers, but we also brew kettle soured beers, stouts and wild/sour ales.</p>
          <p>Due to Icelandic law it is prohibited to sell alcohol directly
          to individuals. Our beers are available at <a href="https://www.vinbudin.is/heim/vorur/vorur.aspx/?text=malbygg" target="_blank">Vínbúðin</a> and at bars/restaurants.</p>
        </React.Fragment>
      }
      staff={
        <React.Fragment>
          <b>CFO</b> : Ingi Már Kjartansson – <a href="mailto:ingi@malbygg.is" target="_blank">ingi@malbygg.is</a><br />
          <b>CEO</b> : Andri Þór Kjartansson – <a href="mailto:andri@malbygg.is" target="_blank">andri@malbygg.is</a><br />
          <b>Head brewer</b> : Bergur Gunnarsson – <a href="mailto:bergur@malbygg.is" target="_blank">bergur@malbygg.is</a><br />
          <b>Sales and marketing</b> : Steinn Stefánsson – <a href="mailto:steinn@malbygg.is" target="_blank">steinn@malbygg.is</a><br />
        </React.Fragment>
      }
    />
  </>
);
